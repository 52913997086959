import React from 'react';
import propTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Markdown } from '../../'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../index.css';
import './cardtext.css'
import '../cards.css'

export const CardText = ({ title, body, footerText, footerType, imgCard, pageLink }) => (
  <div>
    <div className={`gsf-card-body`}>
      <div>
        {title ? 
        <Card.Title>{title}</Card.Title>
        : <div/>}
      </div>
      <Card.Text>
        <Markdown>{body}</Markdown>
        <p>
          { pageLink ?
            <a href={pageLink} >Read More</a>
          : <div/>}
        </p>
      </Card.Text>
    </div>
    <div className='vert-card-footer'>
      {imgCard == 'vertical' ?
        <div>
          {footerText ? 
            <div>
              {footerType ? 
                <Card.Footer>
                  <small>{footerText}</small>
                </Card.Footer>
                : 
                <div className='gsf-card-body'>
                  <Card.Text style={{marginTop: '0px'}}>
                    <small className='text-muted'>{footerText}</small>
                  </Card.Text>
                </div>
              }
            </div>
          : <div/>}
        </div>
      :
        <div>
          {footerText ? 
            <div>
              {footerType ? 
                <Card.Footer>
                  <small>{footerText}</small>
                </Card.Footer>
                : 
                <div className='gsf-card-body'>
                  <Card.Text style={{marginTop: '0px'}}>
                    <small className='text-muted'>{footerText}</small>
                  </Card.Text>
                  <div>
                    { pageLink ?
                      <a href={pageLink} >Read More</a>
                    : <div/>}
                  </div>
                </div>
              }
            </div>
          : <div/>}
        </div>
      }
    </div>
  </div>
);

CardText.propTypes = {
  title: propTypes.string,
  body: propTypes.string.isRequired,
  footerText: propTypes.string,
  footerType: propTypes.oneOf(['shaded']),
  imgCard: propTypes.oneOf(['vertical-left', 'vertical-right', 'horizontal']),
  pageLink: propTypes.string
};

CardText.defaultProps = {
  pageLink: ''
}