import styled from "styled-components";
import { px2vw } from '../../../utils';

const calcHeight = px2vw(250, 250);
const cardWidth = px2vw(500, 500);

export const VerticalImage = styled.div`
  height: ${calcHeight};
  max-height: 200px;
  max-width: 50%;
  padding: 10px;
`;

export const CardVertical = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VerticalImageText = styled.div`
  display: flex;  
  flex-direction: column;
  max-height: 100vw;
  min-height: 200px;
  width: ${cardWidth - VerticalImage.width}
`;