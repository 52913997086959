import { gql } from '@apollo/client'

export const OurBoardQuery = gql`
  {
    biographies (where:{employeeType:"Board"} sort: "ranking:asc"){
      name
      title
      body
      photo {
        url
      }
    }
  }
`

export const OurMembersQuery = gql`
  {
    biographies (where:{employeeType:"Member"} sort: "ranking:asc"){
      name
      title
      body
      photo {
        url
      }
    }
  }
`