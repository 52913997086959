import { gql } from '@apollo/client'

export const AboutUsQuery = gql `
  {
    aboutUsContents (sort: "ranking:asc"){
      title
      body
    }
    aboutUsFormatting{
      imageDivider{
        formats
      }
    }
  }
`