import React from 'react';
import propTypes from 'prop-types';

import { Markdown } from '../../'
import { monthNames } from '../../../utils'
import './articlepage.css'
import '../../../index.css'

export const ArticlePageContent = ({uid, imageSrc, imageAlign, title, body, publishDate, facebookImage}) => {
  let publish = new Date(publishDate)
  console.log(facebookImage)
  let parsedLink = `https://www.facebook.com/sharer/sharer.php?[images][0]=${encodeURIComponent(facebookImage)}&u=${encodeURIComponent(`https://greenstarfamilies.org/articles/${uid}`)}`
  return (
    <div className="main-content">
      <div>
        { imageSrc ? 
        <div className='article'>
          {imageAlign == 'left' ?
            <img src={imageSrc} align={imageAlign} style={{margin: '0 2vw 2vw 0'}}/>
            :
            <img src={imageSrc} align={imageAlign} style={{margin: '0 0 2vw 2vw'}}/>
          }
          
        </div>
        : <div/>
        }
      </div>
      <div>
        <h2>{title}</h2>
      </div>
      <div className='align-left'>
      <p><span style= {{fontSize: '14px'}}><strong>Published on:</strong> {monthNames[publish.getMonth()]} {publish.getDate()}, {publish.getFullYear()}</span></p>
      <Markdown>
        {body}
      </Markdown>
      </div>
      <div>
        {facebookImage ? 
        <div>
          <div class="fb-share-button" 
            data-href={facebookImage}
            data-layout="button" data-size="large">
              <a target="_blank" href={parsedLink}
                class="fb-xfbml-parse-ignore">Share</a></div>
        </div>
        : <div/>}
      </div>
    </div>
)};

ArticlePageContent.propTypes = {
  uid: propTypes.string,
  imageSrc: propTypes.string,
  imageAlign: propTypes.oneOf(['left', 'right']),
  title: propTypes.string,
  body: propTypes.string,
  publishDate: propTypes.string,
  facebookImage: propTypes.string
};

ArticlePageContent.defaultProps = {
  facebookImage: null
};