import React from 'react';
import propTypes from 'prop-types';
import { Nav, Navbar, Collapse, NavDropdown, FormControl, FormGroup } from 'react-bootstrap'
import { useQuery } from '@apollo/client';

import { HeaderQuery } from '../../queries'
import { Button } from '../Buttons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../index.css';
import './header.css';

export const Header = ({ showSearch, showDonate }) => {
  return (
  <Navbar className='wrapper' variant="dark" expand="lg" collapseOnSelect>
      <Navbar.Brand href='/'>
        <img src="/inverted.svg"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="menu-right">
          <Nav.Link className="menu-link" href="/">Home</Nav.Link>
          <NavDropdown className="menu-link" href="/about-us" title="About Us">
            <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
            <NavDropdown.Item href="/our-team/board">Our Board</NavDropdown.Item>
            <NavDropdown.Item href="/our-team/members">Our Members</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav varient="pills">
          { showSearch ?
            <Nav.Item>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            </Nav.Item>
          :
            <div />
          }
          { showDonate ?
            <Nav.Item>
              <Button size="large" label="Donate" primary="True" onClick={() => {window.location='/donations'}}/>
            </Nav.Item>
          :
            <div />
          }
        </Nav>
      </Navbar.Collapse>
  </Navbar>
)};

Header.propTypes = {
  showSearch: propTypes.bool,
  showDonate: propTypes.bool
};

Header.defaultProps = {
  showSearch: true,
  showDonate: true
};

export const DynamicHeader = () => {
  const { data } = useQuery(HeaderQuery)
  if ( !data ) return <Header showDonate={false} showSearch={false} />
  console.log(data.featureControl.showSearch)
  return(
    <Header
      showDonate={data.featureControl.showDonate}
      showSearch={data.featureControl.showSearch}
    />
  )
}
