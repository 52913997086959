import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

import { HomeCarousel, Markdown, FullCard } from '../../';
import { ContainerHolder, ColumnContainer, CardBox } from './ColumnFormatting'
import './homepage.css'
import '../DemoPage/page.css'

export const HomePageContent = ({ images, body, events, news }) => (
  <div className="main-content">
    <div className='center-content'>
      <HomeCarousel images={images}/>
    </div>
    <div className='center center-body'>
      <Markdown>{body}</Markdown>
    </div>
    <ContainerHolder>
      <ColumnContainer>
        <h3 className='center'>Events</h3>
        {events.map(
          (eventCard) => (
            <CardBox>
              { eventCard.image ? 
                <FullCard
                  imgPosition={eventCard.imgAlign ? eventCard.imgAlign : 'left'}
                  imgSrc={eventCard.image.url}
                  title={eventCard.title}
                  body={eventCard.summary}
                  footerText={moment(eventCard.publishDate).fromNow()}
                  footerType={eventCard.footerType}
                  pageLink={eventCard.pageLink}
                />
              :
                <FullCard
                  title={eventCard.title}
                  body={eventCard.summary}
                  footerText={moment(eventCard.publishDate).fromNow()}
                  footerType={eventCard.footerType}
                  pageLink={eventCard.pageLink}
                />
              }
            </CardBox>
          )
        )}
      </ColumnContainer>
      <ColumnContainer>
        <h3 className='center'>News</h3>
        {news.map(
          (newsCard) => (
            <CardBox>
              { newsCard.image ? 
                <FullCard
                  imgPosition={newsCard.imgAlign ? newsCard.imgAlign : 'left'}
                  imgSrc={newsCard.image.url}
                  title={newsCard.title}
                  body={newsCard.summary}
                  footerText={moment(newsCard.publishDate).fromNow()}
                  footerType={newsCard.footerType}
                  pageLink={newsCard.pageLink}
                />
              :
                <FullCard
                  title={newsCard.title}
                  body={newsCard.summary}
                  footerText={moment(newsCard.publishDate).fromNow()}
                  footerType={newsCard.footerType}
                  pageLink={newsCard.pageLink}
                />
              }
            </CardBox>
          )
        )}
      </ColumnContainer>
    </ContainerHolder>
  </div>
);

HomePageContent.propTypes = {
  images: propTypes.array,
  body: propTypes.string, 
  events: propTypes.array,
  news: propTypes.array
};

HomePageContent.defaultProps = {
};