import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

import './App.css';
import { DynamicHeader, DynamicFooter, ProjectRouter } from './components/'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER}/graphql`,
  useGETForQueries: true
})

const client = new ApolloClient({
  link: httpLink,
  fetchOptions: {
    mode: 'no-cors'
  },
  cache: new InMemoryCache()
})

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <DynamicHeader />
        <div className="Main-page">
          <ProjectRouter/>
        </div>
        <DynamicFooter/>
      </div>
    </ApolloProvider>
  );
}

export default App;
