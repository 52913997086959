import { gql } from '@apollo/client'

export const SingleNewsItemQuery = ( uid ) => gql`
{
  newsItems(where: { uid: "${uid}" }){
    title
    body
    image {
     url
    }
    imageAlign
  }
}`

export const HomePageNewsItemsQueries = gql`
{
  newsItems(sort: "publishDate:desc", limit: 5){ 
    uid
    title
    body
    image {
     url
    }
    imageAlign
  }
}
`