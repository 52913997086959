import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';

import { Button } from '../../Buttons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../index.css';
import './donation.css';

export const Donations = ({ tenDonation, twentyDonation, fiftyDonation, hundredDonation }) => (
  <div>
    <div>
      <h2>Donate to Green Star Families of America</h2>
    </div>
    <div>
      <p>Click one of the buttons below to donate to Green Star Families of America</p>
      <div>
        <Button className='donate-buttons' size="large" label="Donate $10" primary={true} onClick={() => {window.location = tenDonation}}/>
        <Button className='donate-buttons' size="large" label="Donate $20" primary={true} onClick={() => {window.location = twentyDonation}}/>
        <Button className='donate-buttons' size="large" label="Donate $50" primary={true} onClick={() => {window.location = fiftyDonation}}/>
        <Button size="large" label="Donate $100" primary={true} onClick={() => {window.location = hundredDonation}}/>
      </div>
    </div>
  </div>
);

Donations.propTypes = {
  tenDonation: PropTypes.string,
  twentyDonation: PropTypes.string,
  fiftyDonation: PropTypes.string,
  hundredDonation: PropTypes.string
};

Donations.defaultProps = {
  tenDonation: 'https://buy.stripe.com/4gw7vmdFB2LdcGk000',
  twentyDonation: 'https://buy.stripe.com/5kA4jafNJgC3fSw3cd',
  fiftyDonation: 'https://buy.stripe.com/9AQ9DugRN3PhcGkcMO',
  hundredDonation: 'https://buy.stripe.com/bIY02U1WTetV35K9AD'
};