import React from 'react';
import propTypes from 'prop-types';
import ReactMarkdown from 'react-markdown'

import './markdown.css'

export const Markdown = ({ className, children }) => (
  <ReactMarkdown className={className}>
    {children}
  </ReactMarkdown>
);

Markdown.propTypes = {
  children: propTypes.string,
  className: propTypes.string
};

Markdown.defaultProps = {
  className: ''
};
