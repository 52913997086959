import { gql } from '@apollo/client'

export const FooterQuery = gql `
  {
    siteSetting{
      MailingAddress
      FacebookURL
      InstagramURL
    }
  }
`