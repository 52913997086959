import React from 'react';
import PropTypes from 'prop-types';

import { Header, Footer } from '../..'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../index.css';
import './page.css';

export const Page = ({ children, footer }) => (
  <div className='page-content'>
    <Header/>
    <div className="main-content">
      {children}
    </div>
    <div>
      {footer ? 
        footer
      : <Footer />
      }
    </div>
  </div>
);

Header.propTypes = {
  children: PropTypes.element,
  footer: PropTypes.element
};

Header.defaultProps = {
  footer: <Footer />
};

