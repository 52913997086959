import styled from "styled-components";
import { px2vw } from '../../../utils';

export const ContainerHolder = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-shrink: 0;
  max-width: 687.5px;
  margin-bottom: 0px;
  padding-left: 1%;
  padding-right: 1%

  @media (min-width: 768px){
    margin-right: 0px;
    margin-bottom: ${px2vw(2)};
    width: ${px2vw(325, 768)};
  }
`;

export const CardBox = styled.div`
  display: flex;
  width: ${px2vw(500, 500)};
  min-height: ${px2vw(200, 500)};
  padding-top: ${px2vw(20)};
  padding-bottom: 0;
  margin-left: 3%;
  max-width: 102%;
  height: 100%;
  padding-right: 0px;
  flex-basis: 200px;

  @media (min-width: 768px) {
    width: ${px2vw(320, 768)};
    min-height: ${px2vw(200, 768)};
    padding-bottom: ${px2vw(20)};
    height: 100%;
    margin-left: 0;
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    width: ${px2vw(710)};
    min-height: 30vh;
    padding-bottom: ${px2vw(20)};
    height: 100%;
    flex
  }

  & > a{
    color:rgb(94, 92, 92, 1.0);
  }
  
  & > a:hover{
    color:rgba(94, 92, 92, 1.0);
  }
`;
