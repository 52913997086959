import { gql } from '@apollo/client'

export const HomePageQuery = gql `
{
  homePage{
    body
      mainCarousel{
        src: url
        alt: alternativeText
      }
  }
  news: newsItems(sort: "publishDate:desc", limit: 5){ 
    uid
    title
    body
    summary
    image {
     url
    }
    imageAlign
    publishDate
  }
  events(sort: "publishDate:desc", limit: 5){ 
    uid
    title
    body
    summary
    image {
     url
    }
    imageAlign
    publishDate
  }
}`