import React from 'react';
import { useQuery } from '@apollo/client';

import { AboutUsBlock } from '../../app/components';
import { AboutUsQuery } from '../queries'

export const AboutUsContent = () => {
  const { data } = useQuery(AboutUsQuery)
  if (!data){ return <div/>
  } else {

    const sections = data.aboutUsContents
    const img = data.aboutUsFormatting.imageDivider.formats.thumbnail.url.includes('https') ?  data.aboutUsFormatting.imageDivider.formats.thumbnail.url : `${process.env.REACT_APP_GRAPHQL_SERVER}${data.aboutUsFormatting.imageDivider.formats.thumbnail.url}`

    return(
      <div className="main-content">
        {sections.map(
            (section, index, orig) =>(
              <div>
                <div>
                <AboutUsBlock Title={section.title} Body={section.body} />
                </div>
                  { index != (orig.length -1) ?
                    <div className='center'>
                      <br/> 
                      <img src={img}/>
                      <br/><br/>
                    </div>
                    : 
                    <div>
                      <br/>
                    </div>
                  }
              </div>
            )
          )}
      </div>
    );
  }
}
