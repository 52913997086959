import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTree } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { Markdown } from '../'
import { FooterQuery } from '../../queries'

import '../../index.css'
import './footer.css'
import { useQuery } from '@apollo/client';

const linktree = 'https://linktr.ee/Green_Star_Families_of_America'
export const Footer = ({ address, facebook, instagram }) => (
  <footer>
    <div className='wrapper footer'>
        <div>
          <Markdown>
            {address}
          </Markdown>
        </div>
        <div>
          <a href={facebook}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </div>
        <div>
          <a href={instagram}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div>
          <a href={linktree}>
            <FontAwesomeIcon icon={faTree} />
          </a>
        </div>
    </div>
  </footer>
);

Footer.propTypes = {
  address: propTypes.string,
  facebook: propTypes.string,
  instagram: propTypes.string
};

Footer.defaultProps = {
  address: 'Green Star Families of America  \n1950 Kings Hwy #754  \nSwedesboro, NJ 08085',
  facebook: 'https://www.facebook.com/GREENSTARFAMILIES',
  instagram: 'https://www.intagram.com'
};

export const DynamicFooter = () => {
  const { data } = useQuery(FooterQuery)
  if (!data) return <Footer />

  return(
    <Footer
      address={data.siteSetting.MailingAddress}
      facebook={data.siteSetting.FacebookURL}
      instagram={data.siteSetting.InstagramURL}
    />
  )
}