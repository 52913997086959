import React from 'react';
import propTypes from 'prop-types';

import { Markdown } from '../'
import './aboutusblock.css'

export const AboutUsBlock = ({ Title, Body }) => (
  <div>
    <div className='center'>
      <h2>{Title}</h2>
    </div>
    <div>
      <Markdown>
        {Body}
      </Markdown>
    </div>
  </div>
);

AboutUsBlock.propTypes = {
  Title: propTypes.string,
  Body: propTypes.string
};

AboutUsBlock.defaultProps = {
};