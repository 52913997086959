import React from 'react';
import propTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { CardText, CardImageVertical, CardImageHorizontal } from '../'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../index.css';
import './card.css'
import '../cards.css'

export const FullCard = ({ pageLink, className, imgSize, imgPosition, imgSrc, title, body, footerText, footerType }) => (
  <div className={className}>
    {imgSrc ?
      <div>
        {(imgPosition == 'left' || imgPosition == 'right') ?
          <CardImageVertical
            imgHeight={imgSize}
            imgPosition={imgPosition}
            imgSrc={imgSrc}
            title={title}
            body={body}
            footerText={footerText}
            footerType={footerType}
            pageLink={pageLink}
          />
        :
          <CardImageHorizontal
            imgWidth={imgSize}
            imgPosition={imgPosition}
            imgSrc={imgSrc}
            title={title}
            body={body}
            footerText={footerText}
            footerType={footerType}
            pageLink={pageLink}
          />
        }
      </div>
    :
      <Card>
        <CardText title={title} body={body} footerText={footerText} footerType={footerType} pageLink={pageLink} />
      </Card>
    }
  </div>
);

FullCard.propTypes = {
  pageLink: propTypes.string,
  className: propTypes.string,
  imgSize: propTypes.string,
  imgPosition: propTypes.oneOf(['top', 'bottom', 'left', 'right']),
  imgSrc: propTypes.string.isRequired,
  title: propTypes.string,
  body: propTypes.string.isRequired,
  footerText: propTypes.string,
  footerType: propTypes.oneOf(['shaded'])
};

FullCard.defaultProps = {
  pageLink: ''
};