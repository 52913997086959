import React from 'react';
import propTypes from 'prop-types';
import { Card, Image } from 'react-bootstrap';
import { CardText } from '../'
import { VerticalImage, CardVertical, VerticalImageText } from './cardstyle'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../index.css';
import './cardsimagevertical.css'
import '../cards.css'

export const CardImageVertical = ({className, imgHeight, imgPosition, imgSrc, title, body, footerText, footerType, pageLink }) => (
  <div className={className}>
    <Card>
      {imgPosition == 'left' ?
        <CardVertical>
          <VerticalImage>
            <Image src={imgSrc} style={{height: imgHeight}}/>
          </VerticalImage>
          <VerticalImageText>
            <CardText title={title} body={body} footerText={footerText} footerType={footerType} pageLink={pageLink} imgCard='vertical' />
          </VerticalImageText>
        </CardVertical>
          : 
        <div>
          <div className='column'>
            <CardText title={title} body={body} footerText={footerText} footerType={footerType} pageLink={pageLink} imgCard='vertical' />
          </div>
          <VerticalImage>
            <Image src={imgSrc} style={{height: imgHeight}}/>
          </VerticalImage>
        </div>
      }
    </Card>
  </div>
);

CardImageVertical.propTypes = {
  className: propTypes.string,
  imgHeight: propTypes.string,
  imgPosition: propTypes.oneOf(['left', 'right']).isRequired,
  imgSrc: propTypes.string.isRequired,
  title: propTypes.string,
  body: propTypes.string.isRequired,
  footerText: propTypes.string,
  footerType: propTypes.oneOf(['shaded']),
  pageLink: propTypes.string
};

CardImageVertical.defaultProps = {
  imgHeight: '100%',
  pageLinke: ''
};
