import { gql } from '@apollo/client'

export const SingleArticleItemQuery = ( uid ) => gql`
{
  newsItems(where: { uid: "${uid}" }){
    uid
    title
    body
    publishDate
    image {
     url
    }
    imageAlign
    facebookImage
  }
  events(where: { uid: "${uid}" }){
    uid
    title
    body
    publishDate
    image {
     url
    }
    imageAlign
    facebookImage
  }
}`
