import React from 'react';

import { Donations } from '../../app/components'

export const DonationsContent = () => {
  return(
    <div className="main-content">
      <Donations />
    </div>
  )
}