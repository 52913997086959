import React from 'react';
import propTypes from 'prop-types';

import { Markdown } from '../../'
import './biographypage.css'
import '../../../index.css'

export const BiographyPageContent = ({title, bios}) => (
  <div className="main-content">
    <div className='center-content' style={{marginBottom: '2vw'}}>
      <h2>{title}</h2>
    </div>
    {bios.map((bio, index) => (
      <div className='bio-cell'>
          {bio.photo ? 
            <div className='bio-cell-image'>
              <div className='center-content'>
                <h3>{bio.name}</h3>
              </div>
              <div className='center-content'>
                <h4>{bio.title}</h4>
              </div>
              <div className='bio'>
                {index%2 == 0 ?
                    <img src={bio.photo.url.includes('https') ? bio.photo.url : `${process.env.REACT_APP_GRAPHQL_SERVER}${bio.photo.url}`} align='left' style={{margin: '0 2vw 2vw 0'}}/>
                  :
                    <img src={bio.photo.url.includes('https') ? bio.photo.url : `${process.env.REACT_APP_GRAPHQL_SERVER}${bio.photo.url}`} align='right' style={{margin: '0 0 2vw 2vw'}}/>
                }
                <div className='bio-content'>
                  <Markdown>{bio.body}</Markdown>
                </div>
              </div>
            </div>
          : 
            <div className='bio-no-image'>
              <div className='center-content'>
                <h3>{bio.name}</h3>
              </div>
              <div className='center-content'>
                <h4>{bio.title}</h4>
              </div>
              <Markdown>{bio.body}</Markdown>
            </div>
          }
        </div>
    ))}
  </div>
);

BiographyPageContent.propTypes = {
  title: propTypes.string,
  bios: propTypes.array
};

BiographyPageContent.defaultProps = {
  title: 'Our Team'
};