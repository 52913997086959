import { gql } from '@apollo/client'

export const SingleEventQuery = ( uid ) => gql`
{
  events(where: { uid: "${uid}" }){
    title
    body
    image {
     url
    }
    imageAlign
  }
}`

export const HomePageEventsQuery = gql`
{
  events(sort: "publishDate:desc", limit: 5){ 
    id
    title
    body
    image {
     url
    }
    imageAlign
  }
}
`