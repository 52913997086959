import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ArticlePageContent } from '../components'
import { SingleArticleItemQuery } from '../queries'

export const Articles = () => {
  let { uid } = useParams();
  const { data } = useQuery(SingleArticleItemQuery(uid))
  if (!data) { return <div/>
  } else {
    const item = data.newsItems.length > 0 ? data.newsItems[0] : data.events[0]
    const img = item.image ? `${item.image.url}` : null

    return(
      <div>
        <ArticlePageContent
          uid={item.uid}
          imageSrc={img}
          imageAlign={item.imageAlign}
          title={item.title}
          body={item.body}
          publishDate={item.publishDate}
          facebookImage={item.facebookImage}
        />
      </div>
    )
  }
}