import React from "react"
import { useQuery } from '@apollo/client';

import { HomePageContent } from '../components/Page'
import { HomePageQuery } from "../queries";

export const Home = () => {
  let { data } = useQuery(HomePageQuery)
  if (!data){ return <div />
  } else {
    let newsList = []
    let eventsList = []
    for(let item in data.news){
      let newNews = {...data.news[item]}
      newNews.pageLink = newNews.body.length > 0 ? `/articles/${newNews.uid}` : ''
      newsList.push(newNews)
    }
    for(let item in data.events){
      let newEvent = {...data.events[item]}
      newEvent.pageLink = newEvent.body.length > 0 ? `/articles/${newEvent.uid}` : ''
      eventsList.push(newEvent)
    }
    return(
      <HomePageContent
        images={data.homePage.mainCarousel}
        body={data.homePage.body}
        news={newsList}
        events={eventsList}
      />
    )
  }
}