import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { AboutUsContent, Home, Articles, Event, OurBoardContent, OurMembersContent, DonationsContent } from '../../pages'

export const ProjectRouter = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/about-us">
        <AboutUsContent />
      </Route>
      <Route path="/our-team/board">
        <OurBoardContent />
      </Route>
      <Route path="/our-team/members">
        <OurMembersContent />
      </Route>
      <Route path="/donations">
        <DonationsContent />
      </Route>
      <Switch>
        <Route path="/articles/:uid" children={ <Articles/> } />
      </Switch>
    </Switch>
  </Router>
)