import React from 'react';
import propTypes from 'prop-types';
import { Card, CardImg } from 'react-bootstrap';
import { CardText } from '../'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../index.css';
import './cardsimagehorizontal.css';
import '../cards.css'

export const CardImageHorizontal = ({className, imgWidth, imgPosition, imgSrc, title, body, footerText, footerType, pageLink }) => (
  <div className={className}>
    <Card className={(imgWidth == '100%' || imgWidth == '') ? '' : 'gsf-card'}>
      {imgPosition == 'top' ?
        <div className='center'>
          <CardImg varient={imgPosition} src={imgSrc} style={{width: imgWidth}}/>
        </div>
        : <div/>
      }
      <CardText title={title} body={body} footerText={footerText} footerType={footerType} pageLink={pageLink} />
      {imgPosition == 'bottom' ?
        <div className='center'>
          <CardImg varient={imgPosition} src={imgSrc} style={{width: imgWidth}}/>
        </div>
        : <div/>
      }
    </Card>
  </div>
);

CardImageHorizontal.propTypes = {
  className: propTypes.string,
  imgWidth: propTypes.string,
  imgPosition: propTypes.oneOf(['top', 'bottom']).isRequired,
  imgSrc: propTypes.string.isRequired,
  title: propTypes.string,
  body: propTypes.string.isRequired,
  footerText: propTypes.string,
  footerType: propTypes.oneOf(['shaded']),
  pageLink: propTypes.string
};

CardImageHorizontal.defaultProps = {
  imgWidth: '100%',
  pageLink: ''
};
