import React from 'react';
import { useQuery } from '@apollo/client';

import { BiographyPageContent } from '../components'
import { OurBoardQuery, OurMembersQuery } from '../queries'

export const OurBoardContent = () => {
  const { data } = useQuery(OurBoardQuery)
  if (!data) { return <div/>
  } else {
    const sections = data.biographies

    return(
      <BiographyPageContent
        title='Our Board'
        bios={sections}
      />
    )

  }
}

export const OurMembersContent = () => {
  const { data } = useQuery(OurMembersQuery)
  if (!data) { return <div/>
  } else {
    const sections = data.biographies

    return(
      <BiographyPageContent
        title='Our Members'
        bios={sections}
      />
    )

  }
}