import React from 'react';
import propTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../index.css';
import './carousel.css'

export const HomeCarousel = ({ images }) => (
  <div className="carousel-container">
    <Carousel>
      {images.map(
        (image) =>
        <Carousel.Item>
          <div>
            {image.href ? 
            <a href={image.href}>
              <div className='carousel'>
                <img
                  src={image.src}
                  alt={image.name}
                />
              </div>
            </a>
            :
            <div className='carousel'>
              <img
                src={image.src}
                alt={image.name}
              />
            </div>
            }
          </div>
          <div>
            {image.body ?
            <Carousel.Caption>
              {image.title ?
              <h2>{image.title}</h2>
              :<div/>
              }
              <p>{image.body}</p>
            </Carousel.Caption>
            :<div/>
            }
          </div>
        </Carousel.Item>
      )}
    </Carousel>
  </div>
);

HomeCarousel.propTypes = {
  images: propTypes.array
};

HomeCarousel.defaultProps = {
};